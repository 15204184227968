exports.shouldUpdateScroll = () => {
  return false;
};

exports.onInitialClientRender = () => {
  window.scrollTo(0, 0);
  document.documentElement.classList.add("onLoader");

  setTimeout(() => {
    document.documentElement.classList.remove("onLoader");
  }, 10000);
};
